import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerGoogleAdwords() {
    var url = ConfigAPI.baseURL + "google-adwords" + Session.getToken();
    return instance.get(url);
  },
  mostrarGoogleAdwords(id) {
    var url = ConfigAPI.baseURL + "google-adwords/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarGoogleAdwords(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "google-adwords" + Session.getToken();
    return instance.post(url,params);
  },
  editarGoogleAdwords(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "google-adwords/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarGoogleAdwords(id) {
    var url = ConfigAPI.baseURL + "google-adwords/"+ id + Session.getToken();
    return instance.delete(url);
  },   
  getConnectionUri(data) {
    var params = data
    var url = ConfigAPI.baseURL + "google-adwords/url/connection" + Session.getToken();
    return instance.post(url,params);
  },
  editarClientCustomerIDGoogleAdwords(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "google-adwords/"+ data.id + "/clientCustomerID" + Session.getToken();
    return instance.post(url,params);
  },
  cambiarClientCustomerIDGoogleAdwords(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "google-adwords/"+ data.id + "/clientCustomerID/change" + Session.getToken();
    return instance.post(url,params);
  },

  obtenerGoogleAdwordsInformation() {
    var url = ConfigAPI.baseURL + "google-adwords-information" + Session.getToken();
    return instance.get(url);
  },
  mostrarGoogleAdwordsInformation(id) {
    var url = ConfigAPI.baseURL + "google-adwords-information/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarGoogleAdwordsInformation(data, image1) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {      
      if(key!="banners") {
        formData.append(key, data[key]);
      }

      if(key=="banners") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData
    var url = ConfigAPI.baseURL + "google-adwords-information" + Session.getToken();
    return instance.post(url,params,{headers});
  },
  editarGoogleAdwordsInformation(data, image1) {    
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();
    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="banners") {
        formData.append(key, data[key]);
      }

      if(key=="banners") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('image_' + i, value);
          i++
        })
        formData.append('image', i);
      }
    }

    var params = formData
    var url = ConfigAPI.baseURL + "google-adwords-information/"+ data.id + Session.getToken();
    return instance.post(url,params,{headers});
  },
  eliminarGoogleAdwordsInformation(id) {
    var url = ConfigAPI.baseURL + "google-adwords-information/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  
  obtenerReportLabelCustomerGoogleAdwords(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "google-adwords-reports/label-customer" + Session.getToken();
    return instance.post(url,params);
  },  

  obtenerReportGeneralSummaryGoogleAdwords(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "google-adwords-reports/general-summary" + Session.getToken();
    return instance.post(url,params);
  },  
}

export default servicesAPI;
